import { useCallback, useState, useEffect } from "react";
import { AlertPresenter } from "./AlertPresenter";
import * as s from "./AlertStyled";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { useAppSelector } from "redux/hooks";
import { LEVEL_TYPE, ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  PostAlertRequest,
  AlertRuleListResponse,
  GetAlertListResponse,
} from "api/interfaces/alertInterface.interface";
import {
  SORT_DIRECTION,
  BasePagination,
  CustomError,
} from "api/interfaces/commonInterface.interface";
import { getAlertRuleList, postAlertRuleList } from "api/alertAPI";
import { useLocation, useNavigate } from "react-router-dom";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { getAccountTimeFormat } from "utils/timeUtil";
import AlertStatus from "components/blocks/status/AlertStatus";
import { useAuth } from "components/Auth";
import { isEditAble } from "utils/functions";
import { useIntl } from "react-intl";
import { isCheckPermission } from "utils/AccountUtil";
import useApiError from "hook/useApiError";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";

export function AlertContainer(): JSX.Element {
  const navigate = useNavigate();
  const intl = useIntl();
  const auth = useAuth();
  const location: any = useLocation();
  const { handleError } = useApiError();
  
  const isListPage: boolean = location.pathname.endsWith("/rules");

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const queryClient = useQueryClient();

  const [alertRules, setAlertRules] = useState<{ totalEnable: number }>({
    totalEnable: 0,
  });
  const [alertList, setAlertList] = useState<AlertRuleListResponse[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedRuleId, setSelectedRuleId] = useState<AlertRuleListResponse>();
  //status
  const status = {
    totalTitle: "Total",
    totalValue: alertRules.totalEnable,
    // items: [
    //   <Text fontSize={18} color={COLORS.BUTTONS1}>
    //     Enabled {alertRules?.result[0].enabled}
    //   </Text>,
    //   <Text fontSize={18} color={COLORS.BUTTONS1}>
    //     Disabled {alertRules?.result[0].disabled}
    //   </Text>,
    // ],
    isMobileShow: {
      totalTitle: true,
      totalValue: true,
      items: false,
    },
  };

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageAlertRules", selectedAccount)
  );
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAlertRuleQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "createdAt"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setAlertRuleQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "createdAt",
          sortDirection: SORT_DIRECTION.DESC,
        };
      });
    }
  },[location]);


  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageAlertRules", selectedAccount)
      );
    }
  }, [selectedAccount]);

  const displayCount = (autoApply:boolean , nameArray:string[], total:number, count:number)=>{
    if(autoApply){
      if(total === count){
        return "ALL"
      }else{
        if(nameArray !== undefined){
          if(nameArray.length  > 1 ){
            return `${nameArray[0]}+${nameArray.length-1}`
          }else{
            return nameArray[0]
          }
        }else{
          return "ALL"
        }
      }
    }else{
      if(nameArray !== undefined){
        if(nameArray.length  > 1 ){
          return `${nameArray[0]}+${nameArray.length-1}`
        }else{
          return nameArray[0]
        }
      }else{
        return "-"
      }
    }
  }

  // alert rules
  const columns: TableColumn<AlertRuleListResponse>[] =
    selectedAccount.accountLevel !== "EU"
      ? [
          {
            name: "Title",
            grow: 2,
            selector: (row) => row.title,
            sortable: true,
            sortField: "name",
          },
          {
            name: "Organization",
            grow: 1.4,
            selector: (row) => row.autoApply !== undefined && row.autoApply ? `ALL` : row.count
            //   <s.AccountBox>
            //     {row.accountName}
            //     <span className="account-id">{row.accountNumber}</span>
            //   </s.AccountBox>
            // ),
          },
          {
            name: "Created By",
            sortable: true,
            sortField: "createdBy",
            grow: 1.4,
            // minWidth: "160px",
            selector: (row) => (row.createdBy ? row.createdBy : "System"),
            hide: Media.SM,
          },
          // {
          //   name: "Status",
          //   grow: 1,
          //   // minWidth: Media.SM ? "50px" : "120px",
          //   maxWidth: "80px",
          //   cell: (row) => (
          //     <AlertStatus
          //       diskCount={row.systemCount !== undefined ? row.systemCount : 0}
          //       systemCount={row.diskCount !== undefined ? row.diskCount : 0}
          //       videoCount={row.videoCount !== undefined ? row.videoCount : 0}
          //       data={row.status !== undefined ? row.status : []}
          //     />
          //   ),
          //   center: true,
          // },
          // {
          //   name: "System",
          //   grow: 1,
          //   maxWidth: "100px",
          //   selector: (row) =>
          //     row.systemCount !== undefined ? row.systemCount : 0,
          //   center: true,
          //   hide: Media.SM,
          // },
          // {
          //   name: "Disk",
          //   grow: 1,
          //   maxWidth: "80px",
          //   minWidth: "80px",
          //   selector: (row) =>
          //     row.diskCount !== undefined ? row.diskCount : 0,
          //   center: true,
          //   hide: Media.SM,
          // },
          // {
          //   name: "Video",
          //   grow: 1,
          //   maxWidth: "80px",
          //   minWidth: "80px",
          //   selector: (row) =>
          //     row.videoCount !== undefined ? row.videoCount : 0,
          //   center: true,
          //   hide: Media.SM,
          // },

          {
            id: "createdAt",
            name: "Date Created",
            grow: 1.4,
            // minWidth: "150px",
            sortable: true,
            sortField: "createdAt",
            selector: (row) =>
              row.createdAt !== undefined
                ? getAccountTimeFormat(row.createdAt, selectedAccount, true)
                : "",
            hide: Media.SM,
          },
          {
            name: "Description",
            grow: 1.4,
            selector: (row) => row.description,
          },
          // {
          //   name: "Enable",
          //   grow: 1,
          //   cell: (row) => (
          //     <Toggle
          //       isToggled={row.isEnable}
          //       onChange={() => console.log("is toggle on alert rules enable")}
          //     />
          //   ),
          //   hide: Media.SM,
          // },
          {
            name: "",
            minWidth: "175px",
            grow: 1,
            cell: (row) => (
              <s.Row>
                {selectedAccount.accountLevel !== "DW" ? (
                  <RBACWrapper requiredPermissions={"manageAlertRules"}>
                    <TableButton
                      label={selectedAccount.accountLevel === LEVEL_TYPE.EU ? "Edit" : "View"}
                      // disabled={
                      //   !isEditAble(
                      //     selectedAccount,
                      //     auth.user,
                      //     row.createdBy,
                      //     row.email
                      //   )
                      // }
                      onClickBtn={() => onClickEdit(row)}
                    />
                  </RBACWrapper>
                ) : null}
                {/* <span className="row-click-button"> */}
                {/* <TableButton
                  buttonType="view"
                  label="View Alerts"
                  onClickBtn={() => onRowClick(row)}
                  // disabled={
                  //   row.systemCount + row.diskCount + row.videoCount === 0
                  //     ? true
                  //     : false
                  // }
                  // onClickBtn={() => onModal("view")}
                /> */}
                {/* </span> */}
              </s.Row>
            ),
            right: true,
          },
        ]
      : [
          {
            name: "Title",
            grow: 2,
            sortable: true,
            sortField: "name",
            selector: (row) => row.title,
          },
          {
            name: "Recorder",
            grow: 1.4,
            selector: (row) => displayCount(row.autoApply, row.recorders, row.totalRecorderCount, row.count)
            // cell: (row) => (
            //   <s.AccountBox>
            //     {row.accountName}
            //     <span className="account-id">{row.accountNumber}</span>
            //   </s.AccountBox>
            // ),
          },
          {
            name: "Created By",
            grow: 1.5,
            sortable: true,
            sortField: "createdBy",
            // minWidth: "160px",
            selector: (row) => (row.createdBy ? row.createdBy : "System"),
            hide: Media.SM,
          },
          // {
          //   // name: <TableColumnFilterTitle title="Status" />,
          //   name: "Status",
          //   grow: 1,
          //   // minWidth: Media.SM ? "50px" : "120px",
          //   maxWidth: "80px",
          //   cell: (row) => (
          //     <AlertStatus
          //       diskCount={row.systemCount !== undefined ? row.systemCount : 0}
          //       systemCount={row.diskCount !== undefined ? row.diskCount : 0}
          //       videoCount={row.videoCount !== undefined ? row.videoCount : 0}
          //       data={row.status !== undefined ? row.status : []}
          //     />
          //   ),
          //   center: true,
          // },
          // {
          //   name: "System",
          //   grow: 1,
          //   maxWidth: "100px",
          //   selector: (row) =>
          //     row.systemCount !== undefined ? row.systemCount : 0,
          //   center: true,
          //   hide: Media.SM,
          // },
          // {
          //   name: "Disk",
          //   grow: 1,
          //   maxWidth: "80px",
          //   minWidth: "80px",
          //   selector: (row) =>
          //     row.diskCount !== undefined ? row.diskCount : 0,
          //   center: true,
          //   hide: Media.SM,
          // },
          // {
          //   name: "Video",
          //   grow: 1,
          //   maxWidth: "80px",
          //   minWidth: "80px",
          //   selector: (row) =>
          //     row.videoCount !== undefined ? row.videoCount : 0,
          //   center: true,
          //   hide: Media.SM,
          // },

          {
            id: "createdAt",
            name: "Date Created",
            grow: 1.4,
            // minWidth: "150px",
            sortable: true,
            sortField: "createdAt",
            selector: (row) =>
              row.createdAt !== undefined
                ? getAccountTimeFormat(row.createdAt, selectedAccount, true)
                : "",
            hide: Media.SM,
          },
          {
            name: "Description",
            grow: 1.4,
            selector: (row) => row.description,
          },
          // {
          //   name: "Enable",
          //   grow: 1,
          //   cell: (row) => (
          //     <Toggle
          //       isToggled={row.isEnable}
          //       onChange={() => console.log("is toggle on alert rules enable")}
          //     />
          //   ),
          //   hide: Media.SM,
          // },
          // 마지막 버튼
          {
            name: "",
            minWidth: "175px",
            grow: 1,
            cell: (row) => (
              <s.Row>
                <RBACWrapper requiredPermissions={"manageAlertRules"}>
                  <TableButton
                    label={selectedAccount.accountLevel === LEVEL_TYPE.EU ? "Edit" : "View"}
                    // disabled={
                    //   !isEditAble(
                    //     selectedAccount,
                    //     auth.user,
                    //     row.createdBy,
                    //     row.email
                    //   )
                    // }
                    onClickBtn={() => onClickEdit(row)}
                  />
                </RBACWrapper>
                {/* <span className="row-click-button">
                  <TableButton
                    buttonType="view"
                    label="View Alerts"
                    onClickBtn={() => onRowClick(row)}
                    // disabled={checkDisabled(row)}
                  />
                </span> */}
              </s.Row>
            ),
            right: true,
          },
        ];

  const mobileColumns: TableColumn<AlertRuleListResponse>[] =
    selectedAccount.accountLevel !== "EU"
      ? [
          {
            name: "Title",
            grow: 2,
            selector: (row) => row.title,
            sortable: true,
            sortField: "name",
          },
          {
            name: "Organization",
            grow: 1.4,
            selector: (row) => row.autoApply !== undefined && row.autoApply ? `ALL` : row.count
            //   <s.AccountBox>
            //     {row.accountName}
            //     <span className="account-id">{row.accountNumber}</span>
            //   </s.AccountBox>
            // ),
          },

          // {
          //   name: "Status",
          //   grow: 1,
          //   // minWidth: Media.SM ? "50px" : "120px",
          //   maxWidth: "80px",
          //   cell: (row) => (
          //     <AlertStatus
          //       diskCount={row.systemCount !== undefined ? row.systemCount : 0}
          //       systemCount={row.diskCount !== undefined ? row.diskCount : 0}
          //       videoCount={row.videoCount !== undefined ? row.videoCount : 0}
          //       data={row.status !== undefined ? row.status : []}
          //     />
          //   ),
          //   center: true,
          // },

          {
            name: "",
            minWidth: "0",
            grow: 1,
            cell: (row) => (
              <s.Row>
                {selectedAccount.accountLevel === "EU" ? (
                  <RBACWrapper requiredPermissions={"manageAlertRules"}>
                    <TableButton
                      label="Edit"
                      disabled={
                        !isEditAble(
                          selectedAccount,
                          auth.user,
                          row.createdBy,
                          row.email
                        )
                      }
                      onClickBtn={() => onClickEdit(row)}
                    />
                  </RBACWrapper>
                ) : null}
                {/* <span className="row-click-button"> */}
                <TableButton
                  buttonType="view"
                  label="View Alerts"
                  onClickBtn={() => onRowClick(row)}
                  // disabled={
                  //   row.systemCount + row.diskCount + row.videoCount === 0
                  //     ? true
                  //     : false
                  // }
                  // onClickBtn={() => onModal("view")}
                />
                {/* </span> */}
              </s.Row>
            ),
            right: true,
          },
        ]
      : [
          {
            name: "Title",
            grow: 2,
            sortable: true,
            sortField: "name",
            selector: (row) => row.title,
          },
          {
            name: "Recorder",
            grow: 1.4,
            selector: (row) => row.autoApply !== undefined && row.autoApply ? `ALL` : row.count
            // cell: (row) => (
            //   <s.AccountBox>
            //     {row.accountName}
            //     <span className="account-id">{row.accountNumber}</span>
            //   </s.AccountBox>
            // ),
          },
          // {
          //   // name: <TableColumnFilterTitle title="Status" />,
          //   name: "Status",
          //   grow: 1,
          //   // minWidth: Media.SM ? "50px" : "120px",
          //   maxWidth: "80px",
          //   cell: (row) => (
          //     <AlertStatus
          //       diskCount={row.systemCount !== undefined ? row.systemCount : 0}
          //       systemCount={row.diskCount !== undefined ? row.diskCount : 0}
          //       videoCount={row.videoCount !== undefined ? row.videoCount : 0}
          //       data={row.status !== undefined ? row.status : []}
          //     />
          //   ),
          //   center: true,
          // },
          {
            name: "",
            minWidth: "0",

            cell: (row) => (
              <s.Row>
                <RBACWrapper requiredPermissions={"manageAlertRules"}>
                  <TableButton
                    label="Edit"
                    disabled={
                      !isEditAble(
                        selectedAccount,
                        auth.user,
                        row.createdBy,
                        row.email
                      )
                    }
                    onClickBtn={() => onClickEdit(row)}
                  />
                </RBACWrapper>
              </s.Row>
            ),
            right: true,
          },
        ];

  // disabled
  // const checkDisabled = (row: AlertRuleListResponse) => {
  //   return (row.systemCount !== undefined ? row.systemCount : 0) +
  //     (row.diskCount !== undefined ? row.diskCount : 0) +
  //     (row.videoCount !== undefined ? row.videoCount : 0) ===
  //     0
  //     ? true
  //     : false;
  // };
  const onRowClick = (row: AlertRuleListResponse) => {
    // if (checkDisabled(row)) return;
    if(row?.alertRuleId !== undefined){
      onClickEdit(row);
    }
    // navigate(`/alert/rules/detail`, {
    //   state: {
    //     alertRuleId: row.alertRuleId,
    //     title: row.title,
    //   },
    // });
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("alertRule");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      if (isEditModal) {
        queryClient.invalidateQueries("alertRule");
      }
      setIsEditModal(!isEditModal);
    }
    if (type === "view") {
      setIsViewModal(!isViewModal);
    }
  };

  const onClickEdit = (row: AlertRuleListResponse) => {
    if (isCheckPermission("manageAlertRules", selectedAccount)) {
      setSelectedRuleId(row);
      onModal("edit");
    }
  };

  const mutationCreateAlert = useMutation(postAlertRuleList, {
    onSuccess: () => {
      setIsAddModal(false);
      queryClient.invalidateQueries("alertRule");
    },
    onError: (err:CustomError) => {
      handleError(err,
        intl.formatMessage({
          id: "label.alertRule.notify.create.fail",
          defaultMessage: "Alert Rule create failed",
        })
      );
    },
  });

  const onClickCreate = useCallback(
    (alertInfo: PostAlertRequest) => {
      //setIsAddModal(true);
      console.log("alertInfo", alertInfo);
      mutationCreateAlert.mutate({
        payload: alertInfo,
        accountId: selectedAccount.accountId,
      });
    },
    [mutationCreateAlert, selectedAccount.accountId]
  );

  const [alertRuleQueryInfo, setAlertRuleQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 20,
    keyword: "",
    sortType: "createdAt",
    sortDirection: SORT_DIRECTION.DESC,
  });

  const { error, refetch } = useQuery(
    ["alertRule", alertRuleQueryInfo],
    () =>
      getAlertRuleList({
        accountId: selectedAccount.accountId,
        payload: alertRuleQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: isListPage,
      onSuccess: (res: GetAlertListResponse) => {
        if (res.error !== 0 && res.result === undefined) {
          setAlertRules({ totalEnable: 0 });
          setAlertList([]);
          setTotalCount(0);
        } else {
          setAlertRules({ totalEnable: res.page.total });
          setAlertList(res.result);
          setTotalCount(res.page.total);
        }
      },
      onError: (err: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.alert.notify.list.error",
            defaultMessage: "Alert search error.",
          })
        );
      },
      onSettled: (e:any) =>{
        setPending(false);
      }
    }
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if(alertRuleQueryInfo.keyword !== keyword){
        queryNavigate(navigate, location.pathname, {
          pageLimit: alertRuleQueryInfo.pageLimit,
          pageNum: 0,
          keyword: keyword,
          sortType: alertRuleQueryInfo.sortType,
          sortDirection: alertRuleQueryInfo.sortDirection as SORT_DIRECTION,
        }, selectedAccount);
      }else{
        refetch();
      }
      setPending(true);
      // setAlertRuleQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [alertRuleQueryInfo.keyword, alertRuleQueryInfo.pageLimit, alertRuleQueryInfo.sortDirection, alertRuleQueryInfo.sortType, location.pathname, navigate, refetch, selectedAccount]
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== alertRuleQueryInfo.pageNum + 1) {
        queryNavigate(navigate, location.pathname, {
          pageLimit: alertRuleQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: alertRuleQueryInfo.keyword as string,
          sortType: alertRuleQueryInfo.sortType,
          sortDirection: alertRuleQueryInfo.sortDirection as SORT_DIRECTION,
        }, selectedAccount);
        setPending(true);
        // setAlertRuleQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [alertRuleQueryInfo.keyword, alertRuleQueryInfo.pageLimit, alertRuleQueryInfo.pageNum, alertRuleQueryInfo.sortDirection, alertRuleQueryInfo.sortType, location.pathname, navigate, selectedAccount]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setAlertRuleQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setPending(true);
    }
  };

  return (
    <AlertPresenter
      columns={columns}
      mobileColumns={mobileColumns}
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      isViewModal={isViewModal}
      status={status}
      onClickCreate={onClickCreate}
      data={alertList}
      onRowClick={onRowClick}
      paginationPerPage={alertRuleQueryInfo.pageLimit}
      paginationInfo={alertRuleQueryInfo}
      onChangePage={onChangePage}
      handleSort={handleSort}
      paginationTotalRows={totalCount}
      onClickSearch={onClickSearch}
      selectedAccount={selectedAccount}
      selectedRule={selectedRuleId}
      isRowClickEditable={isRowClickEditable}
      tablePending={pending}

    />
  );
}
