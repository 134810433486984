import WARNING_CIRCLE_ICON from "assets/icons/warning_circle_red_border.svg";
import DefaultThumbnail from "../DefaultThumbnail";
import NOT_AVAILABLE_ICON from "assets/icons/warning_circle.svg";
import { getThumbnailErrorString } from "utils/ReportUtil";
import { PiCameraRotate } from "react-icons/pi";
import VideoShareBroken from "assets/images/video/videoshare-broken.png";
import VideoShareLoading from "assets/images/video/videoshare-loading.png";
import VideoShareNoAccess from "assets/images/video/videoshare-noaccess.png";
import { escape } from "lodash";
import * as s from "./ThumbnailStyled";

export type VideoUploadStatus =
  | "presigned" // 10% loading
  | "complete" // encoding 전이므로 loading
  // | "uploading" // 50% loading
  | "encoding" // 80% loading
  | "vtt" // 90% loading
  | "fail" // broken image
  | "cancel" // broken image
  | "success"; // default

// status closed -> expired image 사용
// sharing OFF false -> expired image 사용

type VideoShareThumbnailProps = {
  isRecorderList?: boolean;
  thumbnailUrl: string | undefined;
  isLazy?: boolean;
  uploadStatus: VideoUploadStatus;
  status: string;
  isShare: boolean;
};

function VideoShareThumbnail(props: VideoShareThumbnailProps): JSX.Element {
  // console.log(props.status, props.uploadStatus, props.isShare);
  const { status, uploadStatus, isShare } = props;
  // const status = "open";
  // const uploadStatus: VideoUploadStatus = "encoding";
  // const isShare = true;

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = NOT_AVAILABLE_ICON;
  };

  // console.log(uploadStatus);

  return (
    <DefaultThumbnail>
      {uploadStatus === "success" ? (
        props.thumbnailUrl === undefined || props.thumbnailUrl === "" ? (
          <s.Container className="video-loss-image">
            <img
              src={WARNING_CIRCLE_ICON}
              alt="warning circle icon"
              loading={
                props.isLazy !== undefined && props.isLazy ? "lazy" : "eager"
              }
            />

            <p className="warning-text">Thumbnail Not Available</p>
          </s.Container>
        ) : (
          <s.ImageBg>
            {/* {(!isShare || status === "closed") && (
              <Container className="video-loss-image">
                <img src={VideoShareNoAccess} alt="video-share-noaccess" />
                <p>ACCESS RESTRICTED</p>
              </Container>
            )} */}
            <div className="black-filter"></div>
            <img
              src={encodeURI(props.thumbnailUrl).replace("#", "%23")}
              alt="recorder thumbnail"
              onError={imageOnErrorHandler}
              loading="lazy"
            />
          </s.ImageBg>
        )
      ) : uploadStatus === "fail" || uploadStatus === "cancel" ? (
        <s.Container>
          <img src={VideoShareBroken} alt="video-share-broken" />
        </s.Container>
      ) : (
        <s.Container>
          <img
            src={VideoShareLoading}
            alt="video-share-loading"
            className="rotate"
          />
        </s.Container>
      )}
    </DefaultThumbnail>
  );
}

export default VideoShareThumbnail;
