import * as s from "./MissingStyled";
import { Text } from "components/atoms/text/Text";
import EnterAddress from "./EnterAddress";
import { useCallback, useEffect, useRef, useState } from "react";
import { recorderDataRow } from "./Missing";
import { remove } from "lodash";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { putMissingLocation } from "api/recorderAPI";
import { useAppSelector } from "redux/hooks";
import { useIntl } from "react-intl";

type Props = {
  recorder: recorderDataRow[];
  isModalClose: () => void;
  refreshData: () => void;
  itemRef: any;
};

type Check = {
  [key: number]: boolean;
};

const MissingList = (props: Props): JSX.Element => {
  const intl = useIntl();
  const accountId: string = useAppSelector(
    (state) => state.accountSettings.accountId
  );
  const [isAssigned, setIsAssigned] = useState<boolean>(false);
  const [CheckList, setCheckList] = useState<string[]>([]);

  const listRef = useRef<any>(null);
  const addressRef = useRef<any>(null);
  const mapRef = useRef<any>();

  const [location, setLocation] = useState({
    location: "",
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    const onClick = (event: any) => {
      if (
        props.itemRef.current &&
        !props.itemRef.current.contains(event.target) &&
        listRef.current &&
        !listRef.current.contains(event.target)
      ) {
        props.isModalClose();
        if (addressRef.current && !addressRef.current.contains(event.target))
          setIsAssigned(false);
      }
    };

    //document.body.addEventListener("click", onClick);

    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [props.itemRef, listRef, addressRef]);

  const onChangeEach = (e: any, id: string) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
      setCheckList([...CheckList, id]);
      // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
      setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
  };
  const onChangeAll = useCallback(
    (checked: boolean) => {
      let tempArray = Array.from(CheckList);
      props.recorder.forEach((data) => {
        if (checked) {
          if (!tempArray.includes(data.id)) {
            tempArray.push(data.id);
          }
        } else {
          tempArray = remove(tempArray, function (tmp) {
            return tmp !== data.id;
          });
        }
      });
      setCheckList(tempArray);
    },
    [CheckList, props.recorder]
  );

  const checkAll = useCallback(() => {
    let checked = true;
    props.recorder.forEach((data) => {
      if (!CheckList.includes(data.id)) {
        checked = false;
      }
    });
    return checked;
  }, [CheckList, props.recorder]);

  const [selected, setSelected] = useState<google.maps.LatLngLiteral>({
    lat: 33.8583483,
    lng: -118.0647871,
  });

  const onChangeLocationSelect = useCallback(
    (address: google.maps.LatLngLiteral, addressString: string) => {
      setLocation({
        location: addressString as string,
        latitude: address.lat as number,
        longitude: address.lng as number,
      });

      setSelected(address);
      mapRef.current.updateInputLocation(address);
    },
    []
  );

  const onChangeAddress = (e: any) => {
    setLocation((location) => {
      return {
        ...location,
        location: e.target.value as string,
      };
    });
  };

  const mutationMissingLocation = useMutation(putMissingLocation, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.dash.notify.missing.update.success",
          defaultMessage: "Recorder location updated successfully.",
        })
      );
      setCheckList([]);
      props.refreshData();
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.dash.notify.missing.update.fail",
          defaultMessage: "Fail to update Recorder location",
        })
      );
    },
  });

  const onApply = useCallback(() => {
    if (CheckList.length === 0) {
      return;
    }

    if (location.location === "" || location.latitude === 0) {
      return;
    }

    mutationMissingLocation.mutate({
      payload: {
        location: location,
        recorderIds: CheckList,
      },
      accountId: accountId,
    });
  }, [CheckList, accountId, location, mutationMissingLocation]);

  return (
    <s.MissingWrapper>
      <s.ListContainer
        ref={listRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <s.ListTitle>
          <input
            className="input_checkbox"
            type="checkbox"
            // onChange={() => setIsAssigned(!isAssigned)}
            onChange={(e) => onChangeAll(e.currentTarget.checked)}
            checked={checkAll()}
          />
          <Text bold fontSize={14}>
            Select All
          </Text>
        </s.ListTitle>

        <s.ListWrapper>
          {props.recorder.map((item, index) => (
            <label key={index}>
              <s.ListItem>
                <span>
                  <input
                    className="input_checkbox"
                    type="checkbox"
                    name={item.id}
                    onChange={(e) => onChangeEach(e, item.id)}
                    checked={CheckList.includes(item.id)}
                  />

                  <Text fontSize={14} color="#828B9B" className="recorder-name">
                    {item.label}
                  </Text>
                </span>
              </s.ListItem>
            </label>
          ))}
        </s.ListWrapper>

        <EnterAddress
          addressRef={addressRef}
          isLoaded={true}
          mapRef={mapRef}
          location={location}
          selected={selected}
          onMarkerChange={onChangeLocationSelect}
          onChangeLocationSelect={onChangeLocationSelect}
          onChangeAddress={onChangeAddress}
          onApply={onApply}
          // onClear={onClear}
          // currentAssign={currentAssign}
        />
      </s.ListContainer>
    </s.MissingWrapper>
  );
};

export default MissingList;
