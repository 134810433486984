import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import * as s from "./FloatListStyled";
import * as mediaQuery from "components/MediaQuery";
import WebAppLink from "components/blocks/link/WebAppLink";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  DashboardData,
  DashboardFilterAlert,
} from "api/interfaces/dashboardInterface.interface";
import { TbChevronsUp, TbChevronsDown } from "react-icons/tb";

import { motion, AnimatePresence } from "framer-motion";
import {
  Alert,
  SelectedAlertView,
} from "api/interfaces/alertInterface.interface";
import { useMediaQuery } from "react-responsive";
import SPECTRUM_VERTICAL from "assets/images/spectrum-vertical.png";
import VMAX from "assets/images/vmax.png";
import { setAlerts } from "redux/reducers/map/mapFilter";

type Props = {
  innerSize: { [key: string]: number };

  onClickRecorder: (id: string) => void;
  onSelectRecorder: (rec: DashboardData) => void;
  isViewRecorderList: boolean;
  selectedAlert: SelectedAlertView | null;
};

type AlertType = "systemAlert" | "diskAlert" | "videoAlert";
interface TypeStatus {
  systemAlert: boolean;
  diskAlert: boolean;
  videoAlert: boolean;
}

const changeTypeString = (key: AlertType) => {
  switch (key) {
    case "systemAlert":
      return "systemAlerts";
    case "diskAlert":
      return "diskAlerts";
    case "videoAlert":
      return "videoAlerts";
    default:
      return `${key}s`;
  }
};
const alertItems = [
  {
    name: "System",
    key: "systemAlert",
  },
  {
    name: "Disk",
    key: "diskAlert",
  },
  {
    name: "Video",
    key: "videoAlert",
  },
];

const changeType = (type: string) => {
  switch (type) {
    case "NVR":
      return VMAX;
    case "Spectrum":
      return SPECTRUM_VERTICAL;
    default:
      return "";
  }
};

const updateAlertTypeStatus = (filterAlert: DashboardFilterAlert) => {
  const newAlertTypeStatus: TypeStatus = {
    systemAlert:
      filterAlert.systemAlerts.notResponding &&
      filterAlert.systemAlerts.abnormalRestart &&
      filterAlert.systemAlerts.systemOverheated,
    diskAlert:
      filterAlert.diskAlerts.hardwareFailure &&
      filterAlert.diskAlerts.diskOverheated &&
      filterAlert.diskAlerts.badSector &&
      filterAlert.diskAlerts.storageStatus &&
      filterAlert.diskAlerts.storageNearFull &&
      filterAlert.diskAlerts.reindexing &&
      filterAlert.diskAlerts.reservedSpaceNotClearing,
    videoAlert:
      filterAlert.videoAlerts.videoLoss && filterAlert.videoAlerts.recording,
  };

  return newAlertTypeStatus;
};

// alert type 안에 있는 모든 요소들 토글처리
const toggleAlertTypeStatus = (status: { [key: string]: boolean }) => {
  return Object.fromEntries(
    Object.entries(status).map(([key, value]) => [key, !value])
  );
};
export const filteringTopAlert = (recorderList: DashboardData[]) => {
  const alertCount = recorderList.reduce(
    (acc, cur) => acc + cur.alert.length,
    0
  );
  const criticalCount = recorderList.reduce(
    (acc, cur) => acc + cur.critical.length,
    0
  );

  if (criticalCount > 0) {
    return "CRITICAL";
  } else if (alertCount > 0) {
    return "ALERT";
  } else {
    return "HEALTHY";
  }
};

const FloatList = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: mediaQuery.mobileMax });
  const [isDetail, setIsDetail] = useState<boolean>(isMobile ? false : true);
  const [topAlert, setTopAlert] = useState<string>("HEALTHY");
  const [alertTypeStatus, setAlertTypeStatus] = useState<TypeStatus>({
    systemAlert: false,
    diskAlert: false,
    videoAlert: false,
  });

  // const dashboardData = changeDashboardDataFormat(props.floatRecorderList);

  const dashboardData = useAppSelector((state) => state.dashboardData);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const alertTypeFilter: DashboardFilterAlert = useAppSelector(
    (state) => state.mapFilter.filters.alerts
  );
  // console.log(dashboardData, "dashboardDAta");
  // recorderList 개수 뺀 배열
  const alertList = useMemo(() => {
    return Object.values(dashboardData).filter((val, index) => {
      return Object.keys(dashboardData)[index] !== "recorderList";
    });
  }, [dashboardData]);

  // 총 RecorderCount https://dw-rnd.atlassian.net/jira/software/c/projects/MD2/boards/14/roadmap
  const totalRecorders = useMemo(() => {
    return alertList
      .map((item: any) => item.recorderCount)
      .reduce((prev, curr) => prev + curr, 0);
  }, [alertList]);

  // 총 AlertCount
  const totalAlerts = useMemo(() => {
    return alertList
      .map((item: any) => item.alertList.length)
      .reduce((prev, curr) => prev + curr, 0);
  }, [alertList]);

  // All Alerts Data
  const totalData: any = useMemo(() => {
    return alertList.reduce(
      (acc: any, cur: any) => {
        let tempArr = [...acc.alertList, ...cur.alertList];
        return {
          systemAlert: acc.systemAlert + cur.systemAlert,
          diskAlert: acc.diskAlert + cur.diskAlert,
          videoAlert: acc.videoAlert + cur.videoAlert,
          // totalAlerts: acc.totalAlerts + cur.alertList.length,
          // recorderCount: acc.recorderCount + cur.recorderCount,
          alertList: tempArr,
        };
      },
      {
        systemAlert: 0,
        diskAlert: 0,
        videoAlert: 0,
        // totalAlerts: 0,
        // recorderCount: 0,
        alertList: [],
      }
    );
  }, [alertList]);

  const onClickType = useCallback(
    (key: AlertType) => {
      dispatch(
        setAlerts({
          ...alertTypeFilter,
          [changeTypeString(key)]: toggleAlertTypeStatus(
            alertTypeFilter[changeTypeString(key)]
          ),
        })
      );
    },
    [alertTypeFilter, alertTypeStatus]
  );

  useEffect(() => {
    setTopAlert(filteringTopAlert(dashboardData.recorderList));
  }, [dashboardData.recorderList]);

  useEffect(() => {
    setAlertTypeStatus(updateAlertTypeStatus(alertTypeFilter));
  }, [alertTypeFilter]);

  return (
    <s.FloatWrapper>
      <mediaQuery.Default>
        <s.TopInfo>
          <s.AlertStatus status={topAlert}>
            <h5>{totalAlerts} Alerts</h5>
            <mediaQuery.Mobile>/</mediaQuery.Mobile>
            <span className="recorder-count">
              <strong>{totalRecorders}</strong> Rec(s)
            </span>
          </s.AlertStatus>
          <s.AlertContents>
            <s.AlertInfo>
              {alertItems.map((item) => (
                <s.AlertInfoItem
                  key={item.key}
                  isOnType={alertTypeStatus[item.key as AlertType]}
                  onClick={() => onClickType(item.key as AlertType)}
                >
                  <p className="title">{item.name}</p>
                  <p className="count">{totalData[item.key]}</p>
                </s.AlertInfoItem>
              ))}
            </s.AlertInfo>
          </s.AlertContents>
        </s.TopInfo>
      </mediaQuery.Default>

      <mediaQuery.Mobile>
        <s.Button onClick={() => setIsDetail(!isDetail)}>
          {isDetail ? <TbChevronsDown size={20} /> : <TbChevronsUp size={20} />}
        </s.Button>
      </mediaQuery.Mobile>

      <mediaQuery.Default>
        {dashboardData.recorderList.length > 0 && (
          <s.Button onClick={() => setIsDetail(!isDetail)}>
            {isDetail ? (
              <TbChevronsUp size={25} />
            ) : (
              <TbChevronsDown size={25} />
            )}
          </s.Button>
        )}
      </mediaQuery.Default>
      <AnimatePresence>
        {isDetail && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <mediaQuery.Mobile>
              <s.TopInfo>
                <s.AlertStatus status={topAlert}>
                  <h5>{totalAlerts} Alerts</h5>
                  <mediaQuery.Mobile>/</mediaQuery.Mobile>
                  <span className="recorder-count">
                    <strong>{totalRecorders}</strong> Rec(s)
                  </span>
                </s.AlertStatus>
                <s.AlertContents>
                  <s.AlertInfo>
                    {alertItems.map((item) => (
                      <s.AlertInfoItem
                        key={item.key}
                        isOnType={alertTypeStatus[item.key as AlertType]}
                      >
                        <p className="title">{item.name}</p>
                        <p className="count">{totalData[item.key]}</p>
                      </s.AlertInfoItem>
                    ))}
                  </s.AlertInfo>
                </s.AlertContents>
              </s.TopInfo>
            </mediaQuery.Mobile>
            <s.AlertDetail>
              {dashboardData.recorderList.map(
                (rec: DashboardData, idx: number) => {
                  let status = "HEALTHY";

                  if (rec.alert.length > 0) status = "ALERT";
                  if (rec.critical.length > 0) status = "CRITICAL";

                  return (
                    // Array.from({ length: 100 }, (_, i) => (
                    <s.RecorderItem
                      key={`recorder-item-${idx}`}
                      status={status}
                      onClick={() => {
                        props.onClickRecorder(rec.recorderId);
                        props.onSelectRecorder(rec);
                      }}
                    >
                      <div className="top">
                        <div className="alert-status-icon">
                          <img
                            src={changeType(rec.type)}
                            alt={`${rec.type}-icon`}
                          />
                          {/* {changeType(rec.type)} */}
                          {/* {status === "ALERT" && <img src={MARKWE_ALERT} />}
                                {status === "HEALTHY" && <img src={MARKWE_HEALTHY} />}
                                {status === "CRITICAL" && <img src={MARKWE_CRITICAL} />} */}
                        </div>

                        <div className="recorder-info">
                          <span className="title">
                            {rec.type === "Spectrum"
                              ? rec.mergedSystemName
                              : rec.name}
                          </span>
                          {rec.type === "Spectrum" && (
                            <span className="mergedSystemName">{rec.name}</span>
                          )}

                          <span className="location">{rec.location}</span>
                        </div>
                      </div>
                      <div
                        className="web-app"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <WebAppLink
                          type={rec.type}
                          recorderId={rec.recorderId}
                          mergedSystemId={rec.mergedSystemId}
                          accountId={selectedAccount.accountId}
                          cloudSystemId={rec.cloudSystemId}
                        />
                      </div>
                    </s.RecorderItem>
                    // ))
                  );
                }
              )}
            </s.AlertDetail>
          </motion.div>
        )}
      </AnimatePresence>
    </s.FloatWrapper>
  );
};

export default FloatList;
