import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS, MAP_STATUS_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

const padding = "14px";
const lightColor = "#fbfbfb";

export const FloatWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : COLORS.PRIMARY2};
  width: 100%;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  ${mediaQuery.isDefault} {
    border-radius: 8px;
  }

  ${mediaQuery.isMobile} {
    margin-top: 8px;
  }
`;

export const TopInfo = styled.div`
  width: 100%;
  box-sizing: border-box;
  ${FLEX.FlexBetweenStart};
  gap: 6px;
  padding: ${padding};

  ${mediaQuery.isDefault} {
    height: 90px;
  }
  ${mediaQuery.isMobile} {
    gap: 10px;
    flex-direction: column;
  }
`;

export const AlertStatus = styled.div<{ status: string }>`
  height: 100%;
  border-radius: 8px;
  background-color: ${({ status }) => MAP_STATUS_COLORS[status]};
  flex: 1;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 8px;
  padding: 12px 10px;
  color: ${COLORS.WHITE};
  min-width: max-content;

  strong,
  h5 {
    font-weight: 500;
  }

  h5 {
    font-size: ${calcRem(18)};
  }

  .recorder-count {
    font-size: ${calcRem(14)};

    strong {
      font-size: ${calcRem(16)};
    }
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    padding: 8px;
    align-items: baseline;
    flex-direction: row;
  }
`;

export const AlertContents = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) =>
    theme.value === "light" ? lightColor : COLORS.PRIMARY1};
  flex: 3;
  ${FLEX.FlexCenterCenter};
  width: 100%;
  height: 100%;

  ${mediaQuery.isMobile} {
    padding: 8px;
  }
`;

export const AlertInfo = styled.ul`
  width: 100%;
  height: 100%;
  flex: 1;
  ${FLEX.FlexBetweenCenter};
`;

export const AlertInfoItem = styled.li<{ isOnType: boolean }>`
  flex: 1;
  text-align: center;
  font-size: ${calcRem(14)};
  ${FLEX.FlexCenterCenter};
  gap: 10px;
  flex-direction: column;
  color: ${({ theme }) => theme.COLORS.WHITE};
  height: 100%;
  border-radius: 8px;
  cursor: pointer;

  .count {
    font-weight: 500;
    font-size: ${calcRem(16)};
  }

  :hover {
    background-color: ${({ theme }) => theme.COLORS.WHITE}10;
  }

  ${({ isOnType, theme }) =>
    isOnType &&
    `
    background-color: ${theme.COLORS.VALIDATERED}10;
    color: ${COLORS.VALIDATERED};
  `}
`;

export const AlertDetail = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;

  /* height: calc(100% - 150px); */
  max-height: calc(100vh - 440px);
  /* max-height: calc(100% - 150px); */
  overflow-y: auto;
  padding: ${padding};
  padding-top: 0;

  ${mediaQuery.isMobile} {
    background-color: ${({ theme }) =>
      theme.value === "light" ? lightColor : COLORS.PRIMARY1};
  }
`;

export const Button = styled.button`
  width: 100%;
  ${FLEX.FlexCenterCenter};
  /* margin-top: 10px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 2px;
  background-color: ${({ theme }) =>
    theme.value === "light" ? lightColor : COLORS.PRIMARY1};

  color: ${({ theme }) => theme.COLORS.SELECTED};
`;

export const RecorderItem = styled.li<{ status: string }>`
  cursor: pointer;
  ${FLEX.FlexBetweenStart};
  color: ${({ theme }) => theme.COLORS.WHITE};
  padding: 12px 0;
  border-bottom: 1px solid #cacaca80;

  .top {
    ${FLEX.FlexStartStart};
    gap: 8px;
  }

  .alert-status-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    height: 40px;
    background-color: ${({ status }) => MAP_STATUS_COLORS[status]};
    font-size: ${calcRem(14)};
    font-weight: 500;
    color: ${COLORS.WHITE};
    ${FLEX.FlexCenterCenter};

    > img {
      /* height: 20px; */
      width: 80%;
    }
  }

  .recorder-info {
    ${FLEX.FlexStartStart};
    gap: 5px;
    flex-direction: column;
    font-size: ${calcRem(14)};
    flex: 1;

    .title {
      font-weight: 500;
      font-size: ${calcRem(16)};
    }
    .location {
      color: ${COLORS.LIGHTGRAY};
    }
  }

  .web-app {
    ${FLEX.FlexEndCenter};
    gap: 4px;
  }
  .web-app-link {
    font-size: ${calcRem(11)};
  }

  :hover {
    background-color: ${({ theme }) =>
      theme.value === "light" ? "#fcfcfc" : `${COLORS.WHITE}10`};
  }
`;

export const DragHandle = styled.div`
  width: 100%;
  ${FLEX.FlexCenterCenter};
  cursor: move;
  padding: 8px ${padding} 0;

  #handle-box {
    border-radius: 8px;
    background-color: ${({ theme }) =>
      theme.value === "light" ? "#cacaca" : COLORS.PRIMARY1};
    width: 26px;
    padding: 2px;
  }
`;
