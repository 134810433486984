import styled from "styled-components";
import WARNING_CIRCLE_ICON from "assets/icons/warning_circle_red_border.svg";
import DefaultThumbnail from "../DefaultThumbnail";
import { getThumbnailErrorString } from "utils/ReportUtil";
import { PiCameraRotate } from "react-icons/pi";
import ThumbnailAvailable from "./ThumbnailAvailable";
import { useEffect, useState } from "react";
import Spinner from "components/atoms/loader/Spinner";
import * as s from "./ThumbnailStyled";

type VideoThumbnailProps = {
  isRecorderList?: boolean;
  videoLoss: boolean;
  thumbnailUrl: string | undefined;
  errorType?: string;
  isLazy?: boolean;
  installationStatus?: string;
  isRefreshing?: boolean;
};

function VideoThumbnail(props: VideoThumbnailProps): JSX.Element {
  const [isThumbnailError, setIsThumbnailError] = useState<boolean>(false);

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.style.display = "none";
    if (!props.videoLoss) {
      setIsThumbnailError(true);
    }
  };

  useEffect(() => {
    setIsThumbnailError(false);
  }, [props.thumbnailUrl]);
  return (
    <>
      {props.installationStatus !== undefined &&
        props.installationStatus === "WAITING" && (
          <SpinnerContainer>
            <Spinner size={25} aria-label="Loading Spinner" />
          </SpinnerContainer>
        )}
      <DefaultThumbnail>
        {props.errorType !== undefined &&
        props.errorType.toLowerCase() !== "success" ? (
          <s.Container
            className="video-loss-image transcoding-image"
            isRecorderList={props.isRecorderList}
          >
            <PiCameraRotate size={24} />
            {!props.isRecorderList && (
              <p>{getThumbnailErrorString(props.errorType)}</p>
            )}
          </s.Container>
        ) : props.thumbnailUrl === undefined || props.thumbnailUrl === "" ? (
          <s.Container
            className="video-loss-image"
            isRecorderList={props.isRecorderList}
          >
            {props.videoLoss ? (
              <>
                <img
                  src={WARNING_CIRCLE_ICON}
                  alt="warning circle icon"
                  loading={
                    props.isLazy !== undefined && props.isLazy
                      ? "lazy"
                      : "eager"
                  }
                />
                {!props.isRecorderList && <p>Video Loss</p>}
              </>
            ) : props.installationStatus !== undefined ? (
              <>
                <PiCameraRotate size={24} />
                {!props.isRecorderList && (
                  <p>{getThumbnailErrorString(props.installationStatus)}</p>
                )}
              </>
            ) : (
              <ThumbnailAvailable isRecorderList={props.isRecorderList} />
            )}
          </s.Container>
        ) : (
          <s.ImageBg>
            {props.videoLoss && (
              <s.Container className="video-loss-image">
                <img
                  src={WARNING_CIRCLE_ICON}
                  alt="warning circle icon"
                  loading={
                    props.isLazy !== undefined && props.isLazy
                      ? "lazy"
                      : "eager"
                  }
                />
                <p>Video Loss</p>
              </s.Container>
            )}
            <div className="black-filter"></div>
            {isThumbnailError && (
              <s.Container className="video-loss-image transcoding-image">
                <ThumbnailAvailable isRecorderList={props.isRecorderList} />
              </s.Container>
            )}
            <img
              src={props.thumbnailUrl}
              alt="recorder thumbnail"
              onError={imageOnErrorHandler}
              loading="lazy"
              className="thumbnail-url"
            />
          </s.ImageBg>
        )}
      </DefaultThumbnail>
    </>
  );
}

export const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 12;
`;

export default VideoThumbnail;
